import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import events from '@kfc-global/react-shared/analytics/Register';
import { getDataLayer } from 'common/utilities/utils';
import isEmpty from 'lodash/isEmpty';
import {LOCAL_STORAGE_KEYS} from 'common/constants/SharedConstants';

/**
 *
 * @param {*} data - all routing data - Array
 */
export const modifyData = (headline = [], supportedDispositions = []) => {
  const { headline: startOrderTitle } = (headline && headline[0] && headline[0].fields) || '';
  const { buttonText } = (supportedDispositions && supportedDispositions[0] && supportedDispositions[0].fields) || '';
  return { startOrderTitle, buttonText };
};

const registerEventForNamedParams = async (eventName) => {
  await AnalyticsService.registerEvent(
    events[eventName].name,
    {
      '%event%': eventName,
    },
    getDataLayer,
  );
};

/**
 * Method to set analytics items in the data layer object
 */
export const setAnalyticsData = () => {
  registerEventForNamedParams('startOrder');
};

/**
 * Method to set analytics items in the data layer object for change button
 */
export const setChangeOrderAnalyticsData = async () => {
  const analyticsItems = [];
  analyticsItems.push({
    event: 'changeStore',
  });
   await AnalyticsService.registerEvent(
    events?.changeStore?.name,
    {
      data: analyticsItems[0],
    },
    getDataLayer
  );
};
//trigger changeStore event if changeFlow is true RENEGBL-16077
export const changeOrderAnalyticsHandler = (scheduleOrderData)=>(scheduleOrderData?.changeFlow || !isEmpty(localStorage?.getItem(LOCAL_STORAGE_KEYS?.DISPOSITION_NEW))) && setChangeOrderAnalyticsData();
